import {useState, useEffect} from 'react'
import axios from 'axios'

import Select from '../../Biblioteca/Select'

const BloquearLiberarDfd = () => {
    const [situacaoPreenchimento, setSituacao] = useState("")

    useEffect(() => {
        axios.get('/api/pca/getSituacaoPreenchimento')
            .then(res => setSituacao(res.data.status))
            .catch(error => alert(error.response.data.message))
    }, [])

    const updateSituacao = (e) => {
        const { value } = e.target
        setSituacao(value)
        axios.post('/api/pca/situacaoPreenchimento', {situacaoPreenchimento: value})
            .then(res => alert(res.data))
            .catch(error => alert(error.response.data.message))
    }

    return (
        <>
            <div style={{ margin: "1vw auto 0 auto" }}>
                <Select key={situacaoPreenchimento} texto="Preenchimento do DFD" nome="situacaoPreenchimetno" valor={situacaoPreenchimento}
                    funcao={updateSituacao} widthWeb="26vw" widthMobile="80vw"
                    options={["Bloqueado", "Liberado"]} />
            </div >
        </>
    )
}

export default BloquearLiberarDfd