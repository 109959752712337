import React, { useContext, useState } from 'react';
import { MyContext } from "../Biblioteca/MyContext"

const NavDfd = (props) => {
    const { myState } = useContext(MyContext);
    const [display, setDisplay] = useState("none")

    const linkStyle = {
        marginLeft: 'auto',
        marginRight: '1rem',
        color: myState === "light" ? 'black' : "#F9F9F9",
        fontWeight: 550,
        fontSize: "13px",
        cursor: 'pointer',
        textDecoration: 'none',
    };

    if (window.matchMedia("(min-width:600px)").matches) {
        return (
            <div style={{ textAlign: "center", width: '100%' }}>
                <a href="/senha" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    SENHA
                </a>
                <a href="/" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    NOVO DFD
                </a>
                <a href="/SolicitarItem" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    INCLUIR ITEM
                </a>
                <a href="/SetAno" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    IMPRESSÃO DFD
                </a>
                <a href="/catsagrupados" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    CATS AGRUPADOS
                </a>
                <a href="/tabelaseadsetor" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    PLANILHA DE ITENS(SEAD)
                </a>
                {/* <a href="/itensCalculo" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    ITENS CÁLCULO
                </a>
                <a href="/impressaoDfd" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    IMPRIMIR DFD
                </a> */}
            </div>
        )
    }
    else {
        return (
            <>
                <div className="mt-2">
                    <a href="/senha" style={{ ...props.estilo, ...linkStyle }}>
                        SENHA
                    </a>
                </div>
                <div className="mt-2">
                    <a href="/" style={{ ...props.estilo, ...linkStyle }}>
                        NOVO DFD
                    </a>
                </div>
                <div className="mt-2">
                    <a href="/SolicitarItem" style={{ ...props.estilo, ...linkStyle }}>
                        INCLUIR ITEM
                    </a>
                </div>
                <div className="mt-2">
                    <a href="/SetAno" style={{ ...props.estilo, ...linkStyle }}>
                        IMPRESSÃO DFD
                    </a>
                </div>
                <div className="mt-2">
                    <a href="/catsagrupados" style={{ ...props.estilo, ...linkStyle }}>
                        CATS AGRUPADOS
                    </a>
                </div>
                <div className='mt-2'>
                    <a href="/tabelaseadsetor" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                        PLANILHA DE ITENS(SEAD)
                    </a>
                </div>
            </>
        )
    }
}

export default NavDfd