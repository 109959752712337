import { useEffect, useState } from 'react'
import axios from 'axios'

import Select from '../../Biblioteca/Select'
import Input from '../../Biblioteca/Input'
import Botao from '../../Biblioteca/Botao'
import formatCurrency from '../../Biblioteca/Funcoes/formatCurrency'

const EscolhaItens = () => {
    const [dados, setDados] = useState({})
    const [search, setSearch] = useState()
    const [searchResultsRemocao, setSearchResultsRemocao] = useState([])
    const [itensEdicao, setItensEdicao] = useState([])
    const [searchResultsAdicao, setSearchResultsAdicao] = useState([])
    const [diferencaValor, setDiferencaValor] = useState(0)

    const updateField = (e) => {
        const { value, name } = e.target
        const tempState = { ...dados }
        tempState[name] = value
        setDados(tempState)
        setSearchResultsRemocao([])
        setItensEdicao([])
        setSearchResultsAdicao([])
        setDiferencaValor(0)
    }

    const updateSearch = (e) => {
        const { name, value } = e.target
        let tempState = { ...dados }
        tempState[name] = value
        setSearch(tempState)
    }

    const buscaItemRemocao = () => {
        if (!dados.fonte || !dados.anoPreenchimentoPca) {
            return alert("É necessário definir uma Regional, Fonte e Ano de Preenchimento para realizar buscas e edições!")
        }
        axios.post('/api/pca/buscarItemRemocao', search)
            .then(res => setSearchResultsRemocao(res.data))
            .catch(error => {
                alert(error.response.data)
                setSearchResultsRemocao([])
            })
    }

    const addItensEdicao = (item, quantidade = 0, indexRegional) => {
        const regionalAtual = item.regionais[indexRegional]
    
        if (itensEdicao.some(el => 
            el._id === item._id && 
            el.regionais.some(reg => 
                reg.fonte === regionalAtual.fonte && 
                reg.anoPreenchimentoPca === regionalAtual.anoPreenchimentoPca && 
                reg.regional === regionalAtual.regional
            )
        )) {
            return alert("O objeto já foi incluído para remoção.")
        }
        const novoItem = {
            ...item,
            regionais: [regionalAtual],
            quantidade,
            indexRegional
        }
        setItensEdicao(prevState => [...prevState, novoItem])
    }

    const buscaItemAdicao = () => {
        if (!dados.fonte || !dados.anoPreenchimentoPca) {
            return alert("É necessário definir uma Regional, Fonte e Ano de Preenchimento para realizar buscas e edições!")
        }
        axios.post('/api/pca/buscarItem', search)
            .then(res => setSearchResultsAdicao(res.data))
            .catch(error => {
                alert(error.response.data)
                setSearchResultsAdicao([])
            })
    }

    const updateItemQuantidade = (index, novaQuantidade) => {
        if (novaQuantidade < 0) return
        setItensEdicao(prevState => {
            const newState = [...prevState]
            const item = newState[index]
            const diferenca = (item.quantidade - novaQuantidade) * item.valorEstimado
            item.quantidade = novaQuantidade
            setDiferencaValor(prevDiferenca => prevDiferenca + diferenca)
            return newState
        })
    }

    const editarPca = () => {
        axios.post('/api/pca/editarPca', { itens: itensEdicao, dados: search })
            .then(res => {
                alert(res.data)
                window.location = "/escolhaitens"
            })
            .catch(error => alert(error.response.data.message))
    }

    return (
        <>
            <div style={{ margin: "4vh 0 4vh 2vw" }}>
                <Select texto="Fonte" nome="fonte"
                    funcao={updateField} widthWeb="26vw" widthMobile="80vw"
                    options={["CBM", "FUNESBOM"]} />
                <Select texto="Ano do Preenchimento" nome="anoPreenchimentoPca"
                    funcao={updateField} widthWeb="26vw" widthMobile="80vw"
                    options={[2024, 2025]} />
            </div>
            <div style={{ textAlign: "center", color: "rgb(51, 52, 143)", fontSize: "1.1rem" }}><b>ITENS PARA REMOÇÃO</b></div>
            <div style={{ marginLeft: "2vw", display: "inline-block" }}>
                <Input texto="Buscar Item" nome="itemRemocao" widthWeb="30vw" widthMobile="80vw" funcao={updateSearch} />
                <Botao texto="BUSCAR" funcao={buscaItemRemocao}
                    align="left" widthMobile="40vw" />
            </div>
            {searchResultsRemocao.length > 0 ? (<>
                <div className="scroll-tabela" style={{ width: "90vw", margin: "2vh auto 2vh auto" }}>
                    <table className="tabelaNovoDfd">
                        <tr>
                            <th className="thNovoDfd" style={{ width: "15vw" }}>OBJETO</th>
                            <th className="thNovoDfd" style={{ width: "25vw" }}>DESCRIÇÃO</th>
                            <th className="thNovoDfd">VALOR ESTIMADO</th>
                            <th className="thNovoDfd">SETOR/REGIONAL</th>
                            <th className="thNovoDfd">QUANTIDADE</th>
                            <th className="thNovoDfd">TOTAL(R$)</th>
                            <th className="thNovoDfd">ÁREA</th>
                            <th className="thNovoDfd">ADICIONAR PARA REMOÇÃO</th>
                        </tr>
                        <tbody>
                            {searchResultsRemocao.map((el, index) => {
                                return el.regionais.map((regional, idx) => {
                                    if (regional.fonte === dados.fonte && regional.anoPreenchimentoPca === parseInt(dados.anoPreenchimentoPca) && regional.qtdEstimada !== 0) {
                                        const valorTotal = el.valorEstimado * regional.qtdEstimada
                                        const descricao = el.descricao.length > 200 ? el.descricao.slice(0, 200) + "... (Ver tabela básica SGC)" : el.descricao
                                        return (
                                            <tr key={`${index}-${idx}`}>
                                                <td className="tdNovoDfd">{el.objeto}</td>
                                                <td className="tdNovoDfd">{descricao}</td>
                                                <td className="tdNovoDfd">{formatCurrency(el.valorEstimado)}</td>
                                                <td className="tdNovoDfd">{regional.regional}</td>
                                                <td className="tdNovoDfd">{regional.qtdEstimada}</td>
                                                <td className="tdNovoDfd">{formatCurrency(valorTotal)}</td>
                                                <td className="tdNovoDfd">{el.areaEipcp}</td>
                                                <td className="tdNovoDfd">
                                                    <Botao texto="Adicionar" funcao={() => addItensEdicao(el, regional.qtdEstimada, idx)} widthWeb="6vw" cor="#dc3545" />
                                                </td>
                                            </tr>
                                        )
                                    }
                                    return null
                                })
                            })}
                        </tbody>
                    </table>
                </div>
            </>) : (<></>)}
            <hr></hr>
            <div style={{ textAlign: "center", color: "rgb(51, 52, 143)", fontSize: "1.1rem" }}><b>ITENS PARA ADIÇÃO</b></div>
            <div style={{ marginLeft: "2vw", display: "inline-block" }}>
                <Input texto="Buscar Item" nome="item" widthWeb="30vw" widthMobile="80vw" funcao={updateSearch} />
                <Botao texto="BUSCAR" funcao={buscaItemAdicao}
                    align="left" widthMobile="40vw" />
            </div>
            {searchResultsAdicao.length > 0 ? (<>
                <div className="scroll-tabela" style={{ width: "90vw", margin: "2vh auto 2vh auto" }}>
                    <table className="tabelaNovoDfd">
                        <tr>
                            <th className="thNovoDfd" style={{ width: "15vw" }}>OBJETO</th>
                            <th className="thNovoDfd" style={{ width: "25vw" }}>DESCRIÇÃO</th>
                            <th className="thNovoDfd">VALOR ESTIMADO</th>
                            <th className="thNovoDfd">ÁREA</th>
                            <th className="thNovoDfd">ADICIONAR PARA INCLUSÃO</th>
                        </tr>
                        <tbody>
                            {searchResultsAdicao.map((el, index) => {
                                const descricao = el.descricao.length > 200 ?
                                    el.descricao.slice(0, 200) + "... (Ver tabela básica SGC)" : el.descricao
                                return (
                                    <tr key={index}>
                                        <td className="tdNovoDfd">{el.objeto}</td>
                                        <td className="tdNovoDfd">{descricao}</td>
                                        <td className="tdNovoDfd">{formatCurrency(el.valorEstimado)}</td>
                                        <td className="tdNovoDfd">{el.areaEipcp}</td>
                                        <td className="tdNovoDfd">
                                            <Botao texto="Adicionar" funcao={() => addItensEdicao(el)}
                                                widthWeb="6vw" />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </>) : (<></>)}
            <hr />
            <div style={{ textAlign: "center", color: "rgb(51, 52, 143)", fontSize: "1.1rem" }}><b>EDIÇÃO DO PCA</b></div>
            {itensEdicao.length > 0 ? (<>
                <div className="scroll-tabela" style={{ width: "90vw", margin: "2vh auto 2vh auto" }}>
                    <table className="tabelaNovoDfd">
                        <tr>
                            <th className="thNovoDfd" style={{ width: "15vw" }}>OBJETO</th>
                            <th className="thNovoDfd" style={{ width: "25vw" }}>DESCRIÇÃO</th>
                            <th className="thNovoDfd">VALOR ESTIMADO</th>
                            <th className="thNovoDfd">SETOR/REGIONAL</th>
                            <th className="thNovoDfd">QUANTIDADE</th>
                            <th className="thNovoDfd">TOTAL(R$)</th>
                            <th className="thNovoDfd">ÁREA</th>
                        </tr>
                        <tbody>
                            {itensEdicao.map((el, index) => {
                                const descricao = el.descricao.length > 200 ?
                                    el.descricao.slice(0, 200) + "... (Ver tabela básica SGC)" : el.descricao
                                return (
                                    <tr key={index}>
                                        <td className="tdNovoDfd">{el.objeto}</td>
                                        <td className="tdNovoDfd">{descricao}</td>
                                        <td className="tdNovoDfd">{formatCurrency(el.valorEstimado)}</td>
                                        <td className="tdNovoDfd">{el.regionais[0]?.regional || "Sem Registro"}</td>
                                        <td className="tdNovoDfd">
                                            <Input nome="qtdEstimada" tipo="number" valor={el.quantidade}
                                                widthWeb="100%" funcao={(e) => updateItemQuantidade(index, parseInt(e.target.value) || 0)} />
                                        </td>
                                        <td className="tdNovoDfd">{formatCurrency(el.quantidade * el.valorEstimado)}</td>
                                        <td className="tdNovoDfd">{el.areaEipcp}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div>
                        <Botao texto="Editar" funcao={() => editarPca()}
                            widthWeb="6vw" />
                    </div>
                    <div style={{
                        margin: "2vh auto",
                        padding: "1vh 2vw",
                        width: "90vw",
                        textAlign: "center",
                        backgroundColor: "#f8f9fa",
                        border: "1px solid #dee2e6",
                        borderRadius: "0.25rem",
                        color: diferencaValor < 0 ? "red" : "green"
                    }}>
                        <strong>Diferença de Valor: {formatCurrency(diferencaValor)}</strong>
                    </div>
                </div>
            </>) : (<></>)}

        </>
    )
}

export default EscolhaItens