import { useState, useEffect } from 'react'
import axios from 'axios'

const SetoresQueEscolheram = (props) => {
    const [setores, setSetores] = useState([])
    const [tipoUser, setTipoUser] = useState("")

    useEffect(() => {
        axios.post('/api/pca/setoresQueEscolheram', { area: props.area })
            .then(res => {
                setSetores(res.data.setores)
                setTipoUser(res.data.tipoUser)
            })
            .catch(error => alert(error.response.data.message))
    }, [])

    if (tipoUser === "geral" || tipoUser === "interno") return (
        props.area === "" ? (<></>) : (
            <div style={{ margin: "1vw 0 0 4vw" }}>
                <b>Setores que escolheram itens nesta área:</b> {
                    setores.map((setor, index) => {
                        if (index !== 0) return `, ${setor}`
                        else return setor
                    })
                }
            </div>
        )
    )
}

export default SetoresQueEscolheram