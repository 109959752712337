import { useState, useEffect } from "react"
import axios from 'axios'

import './NovoDfd.css'
import Select from '../../Biblioteca/Select'
import formatCurrency from "../../Biblioteca/Funcoes/formatCurrency"
import Input from '../../Biblioteca/Input'
import enviar from '../../Biblioteca/Funcoes/enviar'
import Textarea from '../../Biblioteca/Textarea'
import Botao from '../../Biblioteca/Botao'
import SetoresQueEscolheram from "../Helpers/SetoresQueEscolheram"

let today = new Date()
let year = today.getFullYear()

const NovoDfd = () => {
    const [area, setArea] = useState("")
    const [dados, setDados] = useState([])
    const [inputData, setInputData] = useState([])
    const [inputVersion, setInputVersion] = useState(0)
    const [infoDfd, setInfoDfd] = useState({ anoPreenchimentoPca: year, userId: localStorage.id, areaEipcp: area })
    const [saldo, setSaldo] = useState(null)

    useEffect(() => {
        axios.post(`/api/pca/getSaldoDfd`, { anoPreenchimentoPca: year, userId: localStorage.id })
            .then(res => setSaldo(res.data))
            .catch(error => alert(error.response.data.message))

        axios.post(`/api/pca/getInfoDfd`, { anoPreenchimentoPca: year, userId: localStorage.id, areaEipcp: area })
            .then(res => setInfoDfd(prevInfo => ({
                ...prevInfo,
                justificativa: res.data.justificativa || '',
                resultados: res.data.resultados || '',
                boolVinculacao: res.data.boolVinculacao || '',
                vinculacao: res.data.vinculacao || ''
            })))
            .catch(error => alert(error.response.data.message))

        axios.post(`/api/pca/getDfdByArea`, { area: area })
            .then(res => setDados(res.data))
            .catch(error => alert(error.response.data.message))
    }, [area])

    const updateArea = (e) => {
        const { value } = e.target
        setArea(value)
        setInputData([])
        setInputVersion(prevVersion => prevVersion + 1)
        setInfoDfd(prevInfoDfd => ({
            ...prevInfoDfd,
            areaEipcp: value, justificativa: '', resultados: '', boolVinculacao: '', vinculacao: ''
        }))
    }

    const handleInfoDfd = (e) => {
        const { value, name } = e.target
        const tempState = { ...infoDfd }
        tempState[name] = value
        setInfoDfd(tempState)
    }

    const handleInputChange = (e, { id, indexRegional }) => {
        const { name, value } = e.target

        setInputData(prevState => {
            const dadosItemIndex = dados.findIndex(item => item._id === id)
            let regionalItem = null

            if (dadosItemIndex !== -1) {
                regionalItem = dados[dadosItemIndex].regionais[indexRegional]
            }

            let inputDataItem = prevState.find(item => item.id === id)
            if (!inputDataItem) {
                inputDataItem = regionalItem ? { id, ...regionalItem } : { id }
                inputDataItem[name] = value
                return [...prevState, inputDataItem]
            } else {
                const updatedItem = { ...inputDataItem }

                if (regionalItem) {
                    Object.keys(regionalItem).forEach(key => {
                        if (updatedItem[key] === undefined) {
                            updatedItem[key] = regionalItem[key]
                        }
                    })
                }

                updatedItem[name] = value
                return prevState.map(item => item.id === id ? updatedItem : item)
            }
        })
    }

    const sendItem = (itemData) => {
        axios.post('/api/pca/updateObjDfd', { item: itemData, userId: localStorage.id, anoPreenchimentoPca: year })
            .then(res => {
                alert(res.data.msg)
                setSaldo(prevState => ({ ...prevState, saldo: res.data.saldo }))
            })
            .catch(error => alert(error.response.data.message))
    }

    return (
        <>
            <div className="caixaSaldo">
                {saldo && saldo.saldo ?
                    <div className="internoSaldo">
                        <div className="regionalSaldo">{saldo.regional}({saldo.fonte})</div>
                        {saldo.saldo ? <>{formatCurrency(saldo.saldo)}</> : <>R$ 0,00</>}
                    </div> :
                    <div className="internoSaldo">Carregando saldo...</div>
                }
            </div>
            <div style={{ margin: "1vw 0 0 4vw" }}>
                <Select texto="Área" nome="area" valor={area}
                    funcao={updateArea} widthWeb="26vw" widthMobile="80vw"
                    options={["Atendimento Pré-Hospitalar", "Atividades Técnicas", "Combate a Incêndio Florestal",
                        "Combate a Incêndio Urbano", "Materiais de Construção", "Decoração e Eventos", "Educação Física",
                        "Embarcações, Motores e Reboques", "Ensino, Instrução e Pesquisa", "Equipamento de Som e afins",
                        "Ferramentas e Equipamentos Gerais de Busca e Salvamento", "Gabinete Médico e Odontológico",
                        "Gêneros Alimentícios", "Locação de Imóvel", "Materiais de Cozinha", "Material Bélico",
                        "Material de Limpeza", "Mergulho", "Mobiliário, eletrodomésticos e afins", "Obras, serviços de engenharia ou de manutenção predial",
                        "Operações Aquáticas", "Operações Aéreas", "Operações com Cães", "Operações em Altura",
                        "Outras Ferramentas e Equipamentos", "Outros Serviços", "Papelaria e Escritório", "Projetos Sociais",
                        "Serviços terceirizados com ou sem regime de dedicação exclusiva de mão de obra",, 
                        "Seleções internas e Concursos Público",
                        "Tecnologia da Informação", "Uniformes e acessórios", "Viaturas - Locação",
                        "Viaturas - Manutenção", "Viaturas Administrativas - Aquisição", "Viaturas Operacionais - Aquisição"]} />
            </div>
            <SetoresQueEscolheram key={area} area={area}/>
            {dados.length > 0 ? (<>
                <div style={{ margin: "2vh 0 0 4vw" }}>
                    <Textarea texto="Justificativa" nome="justificativa" widthWeb="90vw"
                        key={area + '-justificativa'} funcao={handleInfoDfd} default={infoDfd.justificativa} />
                    <Textarea texto="Resultados Pretendidos" nome="resultados" widthWeb="90vw"
                        key={area + '-resultados'} funcao={handleInfoDfd} default={infoDfd.resultados} />
                    <Select texto="Vinculação ou dependência com outro objeto?" nome="boolVinculacao"
                        funcao={handleInfoDfd} widthWeb="26vw" widthMobile="80vw"
                        key={area + '-boolVinculacao'} options={["Sim", "Não"]} valor={infoDfd.boolVinculacao} />
                    {infoDfd.boolVinculacao == "Sim" ? (
                        <Textarea nome="vinculacao" widthWeb="90vw"
                            key={area + '-vinculacao'} funcao={handleInfoDfd} default={infoDfd.vinculacao} />
                    ) : null}
                    <Botao texto="Salvar" marginB="1vw"
                        funcao={() => enviar('/api/pca/postInfoDfd', infoDfd)} />
                </div>
                <div className="scroll-tabela">
                    <table className="tabelaNovoDfd">
                        <thead>
                            <tr>
                                <th className="thNovoDfd" rowSpan="2" style={{ width: "15vw" }}>OBJETO</th>
                                <th className="thNovoDfd" rowSpan="2" style={{ width: "25vw" }}>DESCRIÇÃO</th>
                                <th className="thNovoDfd" rowSpan="2" style={{ width: "10vw" }}>UNIDADE</th>
                                <th className="thNovoDfd" rowSpan="2" style={{ width: "10vw" }}>VALOR ESTIMADO</th>
                                <th className="thNovoDfd" colSpan="3" style={{ width: "20vw" }}>QUANTIDADE ESTIMADA NOS ÚLTIMOS 3 ANOS</th>
                                <th className="thNovoDfd" rowSpan="2" style={{ width: "10vw" }}>QTD ESTIMADA</th>
                                <th className="thNovoDfd" rowSpan="2" style={{ width: "10vw" }}>PREVISÃO DE ENTREGA</th>
                                <th className="thNovoDfd" rowSpan="2" style={{ width: "20vw" }}>JUSTIFICATIVA (se necessário)</th>
                                <th className="thNovoDfd" rowSpan="2" style={{ width: "10vw" }}>GRAU DE PRIORIDADE</th>
                                <th className="thNovoDfd" rowSpan="2" style={{ width: "10vw" }}></th>
                            </tr>
                            <tr>
                                <th className="thNovoDfd">2021</th>
                                <th className="thNovoDfd">2022</th>
                                <th className="thNovoDfd">2023</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dados.map((item, indexItem) => {
                                let indexRegional = item.regionais.findIndex(el => el.fonte == saldo.fonte && el.regional == saldo.regional && el.anoPreenchimentoPca == year)
                                return (
                                    <tr key={indexItem}>
                                        <td className="tdNovoDfd">{item.objeto}</td>
                                        <td className="tdNovoDfd">{item.descricao}</td>
                                        <td className="tdNovoDfd">{item.und}</td>
                                        <td className="tdNovoDfd">{formatCurrency(item.valorEstimado)}</td>
                                        <td className="tdNovoDfd">
                                            <Input key={`input-ano1-${item._id}-${inputVersion}`} valor={item.regionais[indexRegional]?.ano1 || ""}
                                                nome="ano1" tipo="number" widthWeb="6vw"
                                                funcao={handleInputChange} additionalArgs={{ id: item._id, indexRegional: indexRegional }} />
                                        </td>
                                        <td className="tdNovoDfd">
                                            <Input key={`input-ano1-${item._id}-${inputVersion}`} valor={item.regionais[indexRegional]?.ano2 || ""}
                                                nome="ano2" tipo="number" widthWeb="6vw" funcao={handleInputChange} additionalArgs={{ id: item._id, indexRegional: indexRegional }} />
                                        </td>
                                        <td className="tdNovoDfd">
                                            <Input key={`input-ano1-${item._id}-${inputVersion}`} valor={item.regionais[indexRegional]?.ano3 || ""}
                                                nome="ano3" tipo="number" widthWeb="6vw" funcao={handleInputChange} additionalArgs={{ id: item._id, indexRegional: indexRegional }} />
                                        </td>
                                        <td className="tdNovoDfd">
                                            <Input key={`input-ano1-${item._id}-${inputVersion}`} valor={item.regionais[indexRegional]?.qtdEstimada || ""}
                                                nome="qtdEstimada" tipo="number" widthWeb="9vw" funcao={handleInputChange} additionalArgs={{ id: item._id, indexRegional: indexRegional }} />
                                        </td>
                                        <td className="tdNovoDfd">
                                            <Select key={`input-ano1-${item._id}-${inputVersion}`} nome="entrega" widthWeb="9vw" funcao={handleInputChange} additionalArgs={{ id: item._id, indexRegional: indexRegional }}
                                                valor={item.regionais[indexRegional]?.entrega || ""} options={["Janeiro", "Fevereiro", "Março", "Abril", "Maio",
                                                    "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]} />
                                        </td>
                                        <td className="tdNovoDfd">
                                            <Input key={`input-ano1-${item._id}-${inputVersion}`} valor={item.regionais[indexRegional]?.justificativa || ""}
                                                nome="justificativa" widthWeb="18vw" funcao={handleInputChange} additionalArgs={{ id: item._id, indexRegional: indexRegional }} />
                                        </td>
                                        <td className="tdNovoDfd">
                                            <Select key={`input-ano1-${item._id}-${inputVersion}`} nome="prioridade" widthWeb="9vw" funcao={handleInputChange} additionalArgs={{ id: item._id, indexRegional: indexRegional }}
                                                valor={item.regionais[indexRegional]?.prioridade || ""} options={["Baixo", "Médio", "Alto"]} />
                                        </td>
                                        <td className="tdNovoDfd" style={{ textAlign: "center" }}>
                                            <button className="btn-salvar" onClick={() => {
                                                const itemData = inputData.find(obj => obj.id === item._id)
                                                if (itemData) sendItem(itemData)
                                            }} >Salvar</button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </>) : (<></>)}
        </>
    )
}

export default NovoDfd